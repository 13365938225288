import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { ArrowRight } from "react-feather";
import { PageBlockTitle, staticImgContainer, CTALink, PageBlockNextPageDivider, primaryColour, InlineExternalLink } from "../../styles/component-stylings";

const PAGE_TITLE = "Benefits";

const CareersBenefitsPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta
                    name='description'
                    content='An overview of the benefits of working at Digital Surveillance Collection from the Australian Federal Police'
                />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <p>DSC is nothing without its people, and we recognise that exceptional work comes from exceptional working conditions.</p>
                <p>Some benefits include:</p>
                <ul>
                    <li>Challenging and meaningful work</li>
                    <li>Competitive salary and benefits</li>
                    <li>Casual work culture and dress</li>
                    <li>Specialised training, mentoring and learning opportunities</li>
                    <li>Flexible working arrangements</li>
                    <li>6 weeks leave a year</li>
                    <li>Fulfilling and diverse career trajectories</li>
                    <li>Excellent work-life balance</li>
                    <li>Gym time</li>
                    <li>Salary packaging available</li>
                    <li>Further study assistance opportunities</li>
                    <li>
                        <InlineExternalLink href='https://jobs.afp.gov.au/work-with-us/benefits-and-support' target='_blank' rel='external'>
                            Learn more
                        </InlineExternalLink>{" "}
                        about the benefits for working for the AFP
                    </li>
                </ul>

                <p>
                    Further details are available in the{" "}
                    <InlineExternalLink href='https://www.afp.gov.au/about-us/afp-enterprise-agreement-2017-2020' target='_blank' rel='external'>
                        AFP Enterprise Agreement 2017-2020
                    </InlineExternalLink>
                    .
                </p>

                <PageBlockTitle>Flexible work arrangements</PageBlockTitle>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 75%" }}
                    src='../../images/home-office.jpg'
                    alt='Home Office'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>
                    Many of our people work remotely Australia-wide. COVID-19 lockdowns were no doubt difficult times for us all, but with our flexible work
                    arrangements, allowing people to work at home and interstate, we were actually the most productive we have ever been.
                </p>
                <p>
                    We encourage a flexible work arrangement because we recognise that times are changing and some people like the flexibility to work remotely.
                </p>
                <p>Feel like 3 days in the office, and 2 at home? Let's talk about it.</p>

                <PageBlockTitle>Self-development and learning</PageBlockTitle>
                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center 65%" }}
                    src='../../images/study.jpg'
                    alt='Studying'
                    placeholder='blurred'
                    loading='lazy'
                />
                <p>
                    Regardless of experience or work background, we strongly encourage self-development and improvement for all our people. We have a number of
                    multi-disciplinary teams for you to bounce ideas off and knowledge share with and it is guaranteed that you will gain interesting and varied
                    skills here.
                </p>
                <p>Are you a fresh graduate and want to specialise in a certain field?</p>
                <p>Are you from industry and want to learn more about covert technologies?</p>
                <p>Do you want to further augment your capabilities through further tertiary study?</p>
                <p>Are you a subject matter expert and want to learn how other areas and countries facilitate law enforcement?</p>
                <p>The better you are, the better we are at protecting Australians and Australia's interests.</p>

                <PageBlockNextPageDivider>
                    <CTALink $invert $borderColour={primaryColour} to='/careers/culture' rel='next'>
                        <span>Culture</span>
                        <ArrowRight />
                    </CTALink>
                </PageBlockNextPageDivider>
            </PageBlock>
        </PageLayout>
    );
};

export default CareersBenefitsPage;
